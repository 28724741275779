import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {connect} from 'react-redux'

import LayoutBody from './Body'
import LayoutHeader from './Header'

import {getSystemSettingsWrapped} from "../../../providers/ReduxProvider/actions/userActions";
import {__} from '../../../utils/translationUtils'
import {AppContainer} from "./styles";
import ErrorBoundary from "../ErrorBoundary";

class LayoutPrivate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      googleTranslatorRegistered: false,
      link: ''
    }
  }

  showPopup() {
    return !window.translatorPopupShowDate || new moment().diff(window.translatorPopupShowDate, 'days') > 1;
  }

  registerTranslatorHandler() {
    const {googleTranslatorRegistered} = this.state

    if (!googleTranslatorRegistered) {
      window.translatorPopupShowDate = undefined
      const googleTranslate = new MutationObserver((() => {
        if (this.showPopup() && (document.documentElement.classList.contains('translated-ltr'))) {
          // eslint-disable-next-line no-alert
          window.confirm(__('disable_browser_automatic_translation'))
          window.translatorPopupShowDate = new moment()
        }
      }))

      googleTranslate.observe(document.documentElement, {
        attributes: true,
        attributeFilter: ['class'],
        childList: false,
        characterData: false
      })
      this.setState({googleTranslatorRegistered: true})
    }
  }

  render() {
    const {children} = this.props
    this.registerTranslatorHandler()
    return (
      <LayoutBody {...this.props}>
        <AppContainer>
          <LayoutHeader location={this.props.location}/>
            {children}
        </AppContainer>
      </LayoutBody>
    )
  }
}

LayoutPrivate.propTypes = {
  children: PropTypes.element,
  systemSettings: PropTypes.object,
  getSystemSettingsWrapped: PropTypes.func
}

export default connect(
  (state) => ({systemSettings: state.ui.systemSettings}),
  {getSystemSettingsWrapped}
)(LayoutPrivate)
