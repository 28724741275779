import React, {ReactNode} from 'react';
import {Alert, message, Result} from 'antd';
import {Button} from 'components';
import {__, T} from '../../../utils/translationUtils';
import {ResponsiveModal} from '../../../providers/StyleProvider/styles';
import {CloudFilled, CopyOutlined} from "@ant-design/icons";
import {CollapseError} from "./styles";

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  modalErrorOpened?: boolean;
  error?: Error;
  errorInfo?: React.ErrorInfo;
  errorInfoText?: string
}

const defaultFallback = __(T.somethingWentWrong);

const {Panel} = CollapseError

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      modalErrorOpened: false,
    };

    this.toggleModalErrorOpen = this.toggleModalErrorOpen.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
  }

  toggleModalErrorOpen(open: boolean) {
    this.setState((prev) => ({
      ...prev,
      modalErrorOpened: open,
    }));
  }

  handleCopy() {
    const {error, errorInfoText} = this.state;

    navigator.clipboard.writeText(`${error?.stack}\n\n${errorInfoText}`)
      .then(() => {
        message.success(T.copiedToClipboard);
      })
      .catch(() => {
        message.error(T.failedToCopy);
      })
  };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState((state) => ({
      ...state,
      error,
      errorInfo,
      errorInfoText: errorInfo.componentStack ? `The above error occurred in the ${errorInfo.componentStack.split('\n')[1].trim()} component:${errorInfo.componentStack}` : '',
    }));
  }

  render() {
    if (this.state.hasError) {
      const {error, errorInfoText} = this.state;

      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
          <Result
            icon={<CloudFilled style={{color: "#004671"}}/>}
            title={this.props.fallback || defaultFallback}
            extra={
              <Button onClick={() => this.toggleModalErrorOpen(true)} title={__(T.showError)}/>
            }
          />
          <ResponsiveModal
            title={__(T.errorLog)}
            visible={this.state.modalErrorOpened}
            centered
            footer={
              <>
                <Button
                  onClick={() => this.toggleModalErrorOpen(false)}
                  title={__(T.close)}
                  type={'text'}
                />
                <Button
                  icon={<CopyOutlined/>}
                  onClick={this.handleCopy}
                >
                  {
                    __(T.copyToClipboard)
                  }
                </Button>
              </>
            }
            onCancel={() => this.toggleModalErrorOpen(false)}
          >
            <Alert
              style={{whiteSpace: "pre-wrap", wordWrap: "break-word"}}
              message={error?.stack}
              description={
                <>
                  <br/>
                  <CollapseError>
                    <Panel header={__(T.componentStackError)} key="1">
                      {errorInfoText}
                    </Panel>
                  </CollapseError>
                </>
              }
              type="error"
            />
          </ResponsiveModal>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
