import React, {useEffect, useState} from 'react'
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Radio,
  Select,
  Space,
  Tabs,
  TreeSelect
} from 'antd'
import moment from 'moment'
import {Icon} from '@ant-design/compatible'
import {Panel} from 'react-bootstrap'

import {api} from '../../providers/ApiProvider'
import Loading from '../../components/Loading'
import Chart from '../../components/Chart'
import {cloneWidget, getChartResultsUrl} from '../../utils/appHelper'
import {hashHistory} from '../../providers/HistoryProvider'
import producePayload from './utility/producePayload'
import produceChartPayload from './utility/produceChartPayload'
import getInitialValues from './utility/getInitialValues'
import getOptions from './utility/getOptions'
import getEnumerationOptions from './utility/getEnumerationOptions'
import filterOperators from './utility/filterOperators'
import getCompaniesTree from './utility/getCompaniesTree'
import Empty from "antd/es/empty"
import NoDashboardSettings from "../../components/Dashboard/NoDashboardSettings"
import {CopyOutlined} from "@ant-design/icons"

import * as config from '../../constants/globalConfiguration'
import * as GlobalS from '../../providers/StyleProvider/styles'
import {Flex} from 'components'
import * as S from './styles'
import { __ } from '../../utils/translationUtils'
import ErrorBoundary from "../../components/UI/ErrorBoundary";

const {SHOW_ALL} = TreeSelect
const {TabPane} = Tabs
const {RangePicker} = DatePicker
const {Option} = Select;

const SimpleLabel = ({value}) => <S.SimpleLabel>{value}</S.SimpleLabel>
const CustomInput = ({value, items}) =>
  <div>{value && __(items?.find((e) => e.fieldKey === JSON.parse(value).fieldKey)?.attributeId)}</div>

const OperatorValueInput = ({member, operator, value, ...props}) => {

  const valueType = JSON.parse(member)?.valueType;

  if (valueType === 'time') {
    return (operator === 'inDateRange' || operator === 'notInDateRange')
      ? <RangePicker {...props}
                     value={Array.isArray(value) ? value.map((val) => moment.isMoment(val) ? moment(val) : null) : null}/>
      : <DatePicker {...props} value={Array.isArray(value) ? null : moment(value)}/>
  }

  let isEnumeration = valueType === 'enumeration';
  let items = (!isEnumeration) ? [] : [];
  let type = (!isEnumeration) ? 'tags' : 'multiple'; //multiple doesn't allow add new options //TODO future impl get enum values + remove add functionality

  return (
    <Select
      {...props}
      value={!moment.isMoment(value) ? value : []}
      mode={type}
      notFoundContent={(!isEnumeration) &&
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{__('Type some values')}</span>}/>}
    >
      {items.map(item => (
        <Option key={item}>{item}</Option>
      ))}
    </Select>);
};

export default ({match: {params: {id: currentId}}}) => {
  const dateFormat = 'YYYY-MM-DD';
  const [data, setData] = useState(null)
  const [activeDropdown, setActiveDropdown] = useState(null)
  const [loading, setLoading] = useState(null)
  const [formValues, setFormValues] = useState({ presentation: 'TABLE', shareWith: 'GLOBAL', editableBy: 'GLOBAL', order: [], dimensions: [], measures: [], limit: 100 })
  const [initValues, setInitValues] = useState({})
  const [form] = Form.useForm()
  const [results, setChartResults] = useState(null)
  const [speedUpDate, setSpeedUpDate] = useState(moment('2021-01-01', dateFormat));

  const payload = producePayload({...formValues, configurationId: data?.firstAttributesConfigurated})
  const chartPayload = produceChartPayload(payload)

  const {attributes, reportsWidgetConfiguration} = data || {}

  // this could be returned by a useMemo if in future the performance will slow down
  const measuresOptions = getOptions(reportsWidgetConfiguration?.measures, attributes)
  const timeDimensionsOptions = getOptions(reportsWidgetConfiguration?.times, attributes)
  const dimensionsOptions = getOptions(reportsWidgetConfiguration?.dimensions, attributes)
  const filtersOptions = getOptions(reportsWidgetConfiguration?.filters, attributes)
  const enumerationsOptions = getEnumerationOptions({reportsWidgetConfiguration, formValues})

  useEffect(() => {
    async function init() {
      setLoading('DATA')

      const firstAttributesConfigurated = await api.get(`${config.prefix()?.REPORT || ''}/cube/configurations`).then((response) => {
        if (response?.data[0]?.id) {
          return response.data[0].id
        }
        return undefined
      })

      if (!firstAttributesConfigurated) {
        setLoading(false)
        setData({firstAttributesConfigurated})
        return
      }

      const companies = await api.get('/hierarchy/organisations?withUsers=true').then((e) => e.data).catch((e) => console.log(e))

      // eslint-disable-next-line no-shadow
      const companiesTree = getCompaniesTree(companies)

      const widgetConfiguration = currentId && await api.get(`${config.prefix()?.REPORT || ''}/widgets/${currentId}`).then((response) => response.data)
      // eslint-disable-next-line no-shadow
      const reportsWidgetConfiguration = await api.get(`${config.prefix()?.REPORT || ''}/widgets/configuration/${firstAttributesConfigurated}`).then((response) => response.data)

      if (widgetConfiguration) {
        const users = await api.get('users?pageSize=200')
          .then((e) => e.data)
          .catch((err) => console.log(err))
        const initialValues = getInitialValues({widgetConfiguration, reportsWidgetConfiguration, users})
        changeDefaultSpeedUpDate(initialValues?.granularity);
        setFormValues(initialValues)
        setInitValues(initialValues)
      }

      const mappings = await api.get('/configurations/valueTypes/mappings').then((response) => response.data)
      setData({
        attributes: await api.get('configurations/attributes').then((response) => response.data),
        widgetConfiguration,
        reportsWidgetConfiguration,
        firstAttributesConfigurated,
        mappings,
        companiesTree
      })
      setLoading(false)
    }

    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId])

  async function handleSubmit() {
    try {
      await form.validateFields()
      setLoading('SUBMIT')
      if (currentId) {
        await api.put(`${config.prefix()?.REPORT || ''}/widgets/${currentId}`, payload)
          .then(() => {
            message.success(__('Widget correctly edited'))
            hashHistory.goBack()
          })
          .catch(() => message.error(__('Cube js: something wrong')))
      } else {
        await api.post(`${config.prefix()?.REPORT || ''}/widgets/`, payload)
          .then(() => {
            message.success(__('Widget correctly created'))
            hashHistory.goBack()
          })
          .catch(() => message.error('Cube js: something wrong'))
      }
    } catch (e) {
      message.error(__('Something wrong. Fix the form errors'))
    }
    setLoading(false)
  }

  const calculatePivot = (input) => {
    const result = []
    // Measures
    if (input.measures) {
      input.measures.forEach((val) => {
        const pivotFound = input?.visualization?.pivotConfig?.find((e) => JSON.parse(e.value).fieldKey === JSON.parse(val).fieldKey)
        if (pivotFound) {
          result.push(pivotFound)
        } else {
          result.push({value: val, pivot: input.timeDimensions ? 'x' : 'y'})
        }
      })
    }

    // Dimensions
    if (input.dimensions) {
      input.dimensions.forEach((val) => {
        const pivotFound = input?.visualization?.pivotConfig?.find((e) => JSON.parse(e.value).fieldKey === JSON.parse(val).fieldKey)
        if (pivotFound) {
          result.push(pivotFound)
        } else {
          result.push({value: val, pivot: 'x'})
        }
      })
    }
    // Time Dimensions
    if (input.timeDimensions && input.granularity) {
      const pivotFound = input?.visualization?.pivotConfig?.find((e) => JSON.parse(e.value).fieldKey === JSON.parse(input.timeDimensions).fieldKey)
      if (pivotFound) {
        result.push(pivotFound)
      } else {
        result.push({value: input.timeDimensions, pivot: 'y'})
      }
    }
    return result
  }

  function changeDefaultSpeedUpDate(granularity) {
    if (!granularity) {
      return;
    }
    let defaultDate;
    switch (granularity) {
      case 'day':
        defaultDate = moment().subtract(10, 'days');
        break;
      case 'week':
        defaultDate = moment().subtract(1, 'months');
        break;
      case 'month':
        defaultDate = moment().subtract(3, 'months');
        break;
      default:
        defaultDate = moment().subtract(6, 'months');
        break;
    }

    setSpeedUpDate(defaultDate);
  }

  function onValuesChange(_, values) {
    setChartResults(null)
    const result = values
    try {
      // 🦄 Here we will update the Form.List items available to be configured THEN by the user: order, percents, pivot.
      changeDefaultSpeedUpDate(result?.granularity);
      setFormValues({
        ...result,
        dateRange: result?.dateRange || 'All time',
        granularity: result?.granularity,
        order: result.dimensions?.reduce((acc, val) => {
          const orderFound = result.order?.find((e) => JSON.parse(e.dimension).fieldKey === JSON.parse(val).fieldKey)
          if (orderFound) return [...acc, orderFound]
          return [...acc, {dimension: val, order: 'none'}]
        }, []) || [],
        percents: result.measures?.filter((measure) => reportsWidgetConfiguration.measures.find(({fieldKey}) => fieldKey === JSON.parse(measure).fieldKey)?.measureName === 'countDistinct')
          ?.reduce((acc, val) => {
            const percentageFound = result.percents?.find((e) => JSON.parse(e.measures).fieldKey === JSON.parse(val).fieldKey)
            if (percentageFound) return [...acc, percentageFound]
            return [...acc, {measures: val, percentage: 'absolute'}]
          }, []) || [],
        runningTotals: result.measures?.filter((measure) => reportsWidgetConfiguration.measures.find(({fieldKey}) => fieldKey === JSON.parse(measure).fieldKey)?.measureName === 'countDistinct')
          ?.reduce((acc, val) => {
            const percentageFound = result.runningTotals?.find((e) => JSON.parse(e.measures).fieldKey === JSON.parse(val).fieldKey)
            if (percentageFound) return [...acc, percentageFound]
            return [...acc, {measures: val, percentage: 'absolute'}]
          }, []) || [],
        visualization: {
          pivotConfig: calculatePivot(result),
          stackResults: result.visualization.stackResults
        }
      })
    } catch (err) {
      console.log(JSON.stringify(err))
      message.error(__('The ORDER, the PERCENTS or the PIVOT field cannot be updated somehow (see console.log)'))
    }
  }

  async function handleGetChartResults({preview}) {
    setLoading('CHART')
    setActiveDropdown(false)
    try {
      await form.validateFields();
      let chartData = null;
      chartData = await api.post(getChartResultsUrl({
        payload,
        firstConfigId: data?.firstAttributesConfigurated,
        name: payload.name
      }), chartPayload).then((response) => response.data)
      if (chartData) setChartResults([chartData])
    } catch (error) {
      !error.response && message.error('Something wrong. Fix the form errors')
    }
    setLoading(false)
  }

  if (!data?.firstAttributesConfigurated && !loading) {
    // TODO: Add link to company (taken from user) -> attribute settings.
    return (
      <div id="main-content">
        <h1 className={`sticky ${!currentId ? 'create' : ''}`}>
          <div className="container">
            {currentId ? __('Edit') : __('Create')} {__('Widget')}
          </div>
        </h1>
        <div className="container">
          <NoDashboardSettings/>
        </div>
      </div>
    )
  }
  return (
    <div id="main-content">
      <h1 className={`sticky ${!currentId ? 'create' : ''}`}>
        <Flex className="container">
          <GlobalS.FullWidth>
            {currentId ? __('Edit') : __('Create')} {__('Widget')}
          </GlobalS.FullWidth>
          {currentId &&
            <Dropdown.Button
              overlay={
                <Menu>
                  <Menu.Item
                    icon={<CopyOutlined/>}
                    onClick={() => cloneWidget(currentId)}
                  >
                    {__('Clone widget')}
                  </Menu.Item>
                </Menu>
              }
            />
          }
        </Flex>
      </h1>
      <div className="container">
        <Loading loading={loading === 'DATA'} />
        {data && (loading !== 'DATA') && initValues && (
          <GlobalS.CustomPanel>
            <Panel.Body>
              <GlobalS.Title>{__('Configuration')}</GlobalS.Title>
              <S.Container>
                <Form
                  onFinish={handleSubmit}
                  form={form}
                  initialValues={initValues}
                  onValuesChange={onValuesChange}
                  layout="vertical"
                >

                  <GlobalS.SubTitle>{__('General')}</GlobalS.SubTitle>
                  <GlobalS.ItemListingContainer columns={3}>
                    <GlobalS.CustomFormItem label={__('Name')} name="name"
                                            rules={[{required: true, message: 'Name required'}]}>
                      <Input/>
                    </GlobalS.CustomFormItem>
                    <GlobalS.CustomFormItem label={__('Description')} name="description">
                      <Input.TextArea rows={1}/>
                    </GlobalS.CustomFormItem>
                    <div/>
                    {currentId && (
                      <GlobalS.CustomFormItem label={__('Last Update DateTime')} name="lastUpdateDateTime">
                        <SimpleLabel/>
                      </GlobalS.CustomFormItem>
                    )}
                    {currentId && (
                      <GlobalS.CustomFormItem label={__('Modified By')} name="modifiedBy">
                        <SimpleLabel/>
                      </GlobalS.CustomFormItem>
                    )}
                  </GlobalS.ItemListingContainer>
                  <GlobalS.SubTitle>{__('Visibility')}</GlobalS.SubTitle>
                  <GlobalS.ItemListingContainer columns={3}>
                    <GlobalS.CustomFormItem label={__('Sharable with')} name="shareWith"
                                            rules={[{required: true, message: __('Share with is required')}]}>
                      <Select allowClear>
                        <Select.Option value="SELF">{__('Private')}</Select.Option>
                        <Select.Option value="ENTITY">{__('Organisations')}</Select.Option>
                        <Select.Option value="GLOBAL">{__('Public')}</Select.Option>
                      </Select>
                    </GlobalS.CustomFormItem>
                    {
                      formValues?.shareWith === 'ENTITY' && (
                        <GlobalS.CustomFormItem label={__('Sharable with entities')} name="shareWithEntities"
                                                rules={[{required: true, message: __('Share with is required')}]}>
                          <TreeSelect
                            style={{width: '100%'}}
                            dropdownStyle={{maxHeight: 500, overflowY: 'scroll'}}
                            treeData={data.companiesTree}
                            fieldNames={{label: 'title', value: 'key', children: 'children'}}
                            treeCheckable
                            multiple
                            treeNodeFilterProp="searchValue"
                            showCheckedStrategy={SHOW_ALL}
                            placeholder={__('Search entity(s)')}
                            maxTagCount={2}
                          />
                        </GlobalS.CustomFormItem>
                      )
                    }
                    {formValues?.shareWith === 'ENTITY' ? <>
                      <div/>
                    </> : <>
                      <div/>
                      <div/>
                    </>}
                    <GlobalS.CustomFormItem label={__('Editable by')} name="editableBy"
                                            rules={[{required: true, message: __('Editable by is required')}]}>
                      <Select allowClear>
                        <Select.Option value="SELF">{__('Private')}</Select.Option>
                        <Select.Option value="ENTITY">{__('Organisations')}</Select.Option>
                        <Select.Option value="GLOBAL">{__('Public')}</Select.Option>
                      </Select>
                    </GlobalS.CustomFormItem>
                    {
                      formValues?.editableBy === 'ENTITY' && (
                        <GlobalS.CustomFormItem label={__('Editable by entities')} name="editableByEntities"
                                                rules={[{required: true, message: __('Editable by is required')}]}>
                          <TreeSelect
                            style={{width: '100%'}}
                            dropdownStyle={{maxHeight: 500, overflowY: 'scroll'}}
                            treeData={data.companiesTree}
                            fieldNames={{label: 'title', value: 'key', children: 'children'}}
                            treeCheckable
                            multiple
                            treeNodeFilterProp="searchValue"
                            showCheckedStrategy={SHOW_ALL}
                            placeholder={__('Search entity(s)')}
                            maxTagCount={2}
                          />
                        </GlobalS.CustomFormItem>
                      )
                    }
                  </GlobalS.ItemListingContainer>

                  <GlobalS.SubTitle>{__('Data model')}</GlobalS.SubTitle>
                  <GlobalS.ItemListingContainer columns={3}>

                    <GlobalS.CustomFormItem label={__('Measures')} name="measures">
                      <Select
                        mode="multiple"
                        allowClear
                        filterOption={(input, option) => option.children.includes(input)}
                      >
                        {measuresOptions?.map(({value, label}) => <Select.Option key={value}
                                                                                 value={value}>{label}</Select.Option>)}
                      </Select>
                    </GlobalS.CustomFormItem>
                    <GlobalS.CustomFormItem label={__('Dimensions')} name="dimensions">
                      <Select
                        mode="multiple"
                        allowClear
                        filterOption={(input, option) => option.children.includes(input)}
                      >
                        {dimensionsOptions?.map(({value, label}) => <Select.Option key={value}
                                                                                   value={value}>{label}</Select.Option>)}
                      </Select>
                    </GlobalS.CustomFormItem>
                    <GlobalS.CustomFormItem label={__('Enumeration')} name="enumeration">
                      <Select allowClear>
                        {enumerationsOptions?.map(({value, label, disabled}) => <Select.Option key={value}
                                                                                               disabled={disabled}
                                                                                               value={value}>{label}</Select.Option>)}
                      </Select>
                    </GlobalS.CustomFormItem>
                    <GlobalS.CustomFormItem label={__('Time')} name="timeDimensions">
                      <Select allowClear>
                        {timeDimensionsOptions?.map(({value, label}) => <Select.Option key={value}
                                                                                       value={value}>{label}</Select.Option>)}
                      </Select>
                    </GlobalS.CustomFormItem>
                    {formValues?.timeDimensions && (
                      <GlobalS.CustomFormItem label={__('Date Range')} name="dateRange">
                        <Select>
                          <Select.Option value="Custom">{__('Custom')}</Select.Option>
                          <Select.Option value="All time">{__('All time')}</Select.Option>
                          <Select.Option value="Today">{__('Today')}</Select.Option>
                          <Select.Option value="Yesterday">{__('Yesterday')}</Select.Option>
                          <Select.Option value="This week">{__('This week')}</Select.Option>
                          <Select.Option value="Last 7 days">{__('Last 7 days')}</Select.Option>
                          <Select.Option value="Last 14 days">{__('Last 14 days')}</Select.Option>
                          <Select.Option value="This Month">{__('This Month')}</Select.Option>
                          <Select.Option value="Last Month">{__('Last Month')}</Select.Option>
                          <Select.Option value="Last 2 Months">{__('Last 2 Months')}</Select.Option>
                          <Select.Option value="Last 3 Months">{__('Last 3 Months')}</Select.Option>
                          <Select.Option value="Last 4 Months">{__('Last 4 Months')}</Select.Option>
                          <Select.Option value="Last 6 Months">{__('Last 6 Months')}</Select.Option>
                          <Select.Option value="Last year">{__('Last year')}</Select.Option>
                        </Select>
                      </GlobalS.CustomFormItem>
                    )}
                    {formValues?.dateRange === 'Custom' && (
                      <GlobalS.CustomFormItem label={__('Custom Range')} name="customDateRange"
                                              rules={[{required: true, message: 'Date range required'}]}>
                        <RangePicker/>
                      </GlobalS.CustomFormItem>
                    )}
                    {formValues?.timeDimensions && (
                      <GlobalS.CustomFormItem label={__('Granularity')} name="granularity">
                        <Select defaultValue={null}>
                          <Select.Option value={null}>{__('Without grouping')}</Select.Option>
                          {/* <Select.Option value="second">Second</Select.Option>
                          <Select.Option value="minute">Minute</Select.Option>
                          <Select.Option value="hour">Hour</Select.Option> */}
                          <Select.Option value="day">{__('Day')}</Select.Option>
                          <Select.Option value="week">{__('Week')}</Select.Option>
                          <Select.Option value="month">{__('Month')}</Select.Option>
                          <Select.Option value="year">{__('Year')}</Select.Option>
                        </Select>
                      </GlobalS.CustomFormItem>
                    )}
                    {/*{formValues?.granularity && (*/}
                    {/*  <GlobalS.CustomFormItem label={__('Remove last granularity bin')} name="filterOutLastGranularityBin" valuePropName="checked">*/}
                    {/*    <Switch style={{ maxWidth: '30px' }} />*/}
                    {/*  </GlobalS.CustomFormItem>*/}
                    {/*)}*/}

                  </GlobalS.ItemListingContainer>
                  <GlobalS.SubTitle>{__('Data model')}</GlobalS.SubTitle>
                  <GlobalS.ItemListingContainer columns={3}>
                    <GlobalS.CustomFormItem label={__('Y Label')} name="yLabel" rules={[{required: false}]}>
                      <Input/>
                    </GlobalS.CustomFormItem>
                    <GlobalS.CustomFormItem label={__('X Label')} name="xLabel" rules={[{required: false}]}>
                      <Input/>
                    </GlobalS.CustomFormItem>
                  </GlobalS.ItemListingContainer>
                  <GlobalS.SubTitle>{__('Filters data')}</GlobalS.SubTitle>
                  <S.SubContainer>

                    <Form.List name="filters">
                      {
                        (fields, {add, remove}) => (
                          <S.Cards>
                            {fields.map((field, index) => {
                              const {member, operator} = formValues?.filters?.[index] || {}
                              return (
                                <S.Card key={field.key}>
                                  <GlobalS.CustomFormItem label={__('Filter Name')} name={[index, 'member']} rules={[{
                                    required: true,
                                    message: __('Filter Name required')
                                  }]}>
                                    <Select showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                      {filtersOptions?.map(({value, label}) => <Select.Option key={value}
                                                                                              value={value}>{label}</Select.Option>)}
                                    </Select>
                                  </GlobalS.CustomFormItem>
                                  {member && (
                                    <GlobalS.CustomFormItem label={__('Operator')} name={[index, 'operator']} rules={[{
                                      required: true,
                                      message: __('Operator required')
                                    }]}>
                                      <Select
                                        placeholder={__("Select an operator")}
                                      >
                                        {filterOperators[JSON.parse(member).valueType]?.map(({value, label}) =>
                                          <Select.Option key={value} value={value}>{label}</Select.Option>)}
                                      </Select>
                                    </GlobalS.CustomFormItem>
                                  )}
                                  {member && operator && (
                                    <GlobalS.CustomFormItem label={__('Value')} name={[index, 'values']}
                                                            rules={[{required: true, message: __('Value required')}]}>
                                      <OperatorValueInput member={member} operator={operator}/>
                                    </GlobalS.CustomFormItem>
                                  )}
                                  {fields.length > 0 && (
                                    <S.DeleteWrapper onClick={() => remove(field.name)}>
                                      <Icon type="delete"/>
                                    </S.DeleteWrapper>
                                  )}
                                </S.Card>
                              )
                            })}
                            <S.AddWrapper>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                              >
                                {__('Add Filter')}
                              </Button>
                            </S.AddWrapper>
                          </S.Cards>
                        )
                      }
                    </Form.List>
                  </S.SubContainer>
                  <GlobalS.SubTitle>{__('Chart Section')}</GlobalS.SubTitle>
                  <GlobalS.ItemListingContainer>
                    <GlobalS.CustomFormItem name="presentation">
                      <Select>
                        <Select.Option value="TABLE"><Icon type="table"/> {__('Table')}</Select.Option>
                        <Select.Option value="LINE"><Icon type="line-chart"/> {__('Line')}</Select.Option>
                        <Select.Option value="PIE"><Icon type="pie-chart"/> {__('Pie')}</Select.Option>
                        <Select.Option value="AREA"><Icon type="area-chart"/> {__('Area')}</Select.Option>
                        <Select.Option value="BAR"><Icon type="bar-chart"/> {__('Bar')}</Select.Option>
                        <Select.Option value="NUMBER"><Icon type="number"/> {__('Number')}</Select.Option>
                      </Select>
                    </GlobalS.CustomFormItem>
                    {(formValues.presentation === 'LINE' || formValues.presentation === 'AREA') && (
                      <GlobalS.CustomFormItem name={['visualization', 'stackResults']}>
                        <Select>
                          <Select.Option value="true"><Icon type="menu"/> {__('Stacked')}</Select.Option>
                          <Select.Option selected value="false"><Icon type="line"/> {__('Not Stacked')}</Select.Option>
                        </Select>
                      </GlobalS.CustomFormItem>
                    )}
                    <Form.List name="order">
                      {
                        (fields) => (
                          <div className="ant-form-item">
                            <Button onClick={() => setActiveDropdown((e) => (e ? null : 'order'))}><Icon
                              type="sort-ascending"/> {__('Order')}</Button>
                            <S.Dropdown isVisible={activeDropdown === 'order' && fields?.length}>
                              {fields?.map((field, index) => (
                                <S.Dropdowninner key={field.key}>
                                  <GlobalS.CustomFormItem name={[index, 'dimension']}>
                                    <CustomInput items={reportsWidgetConfiguration?.dimensions}/>
                                  </GlobalS.CustomFormItem>
                                  <GlobalS.CustomFormItem name={[index, 'order']}>
                                    <Radio.Group buttonStyle="solid">
                                      <Radio.Button value="asc">{__('ASC')}</Radio.Button>
                                      <Radio.Button value="desc">{__('DESC')}</Radio.Button>
                                      <Radio.Button value="none">{__('NONE')}</Radio.Button>
                                    </Radio.Group>
                                  </GlobalS.CustomFormItem>
                                </S.Dropdowninner>
                              ))}
                            </S.Dropdown>
                          </div>
                        )
                      }
                    </Form.List>
                    <Form.List name="percents">
                      {
                        (fields) => (
                          <div className="ant-form-item">
                            <Button onClick={() => setActiveDropdown((e) => (e ? null : 'percents'))}><Icon
                              type="percentage"/> {__('Percentage')}</Button>
                            <S.Dropdown isVisible={activeDropdown === 'percents' && fields?.length}>
                              {fields?.map((field, index) => (
                                <S.Dropdowninner key={field.key}>
                                  <GlobalS.CustomFormItem name={[index, 'measures']}>
                                    <CustomInput items={reportsWidgetConfiguration?.measures}/>
                                  </GlobalS.CustomFormItem>
                                  <GlobalS.CustomFormItem name={[index, 'percentage']}>
                                    <Radio.Group buttonStyle="solid">
                                      <Radio.Button value="absolute">{__('Absolute')}</Radio.Button>
                                      <Radio.Button value="relative">{__('Relative')}</Radio.Button>
                                      <Radio.Button value="keepBoth">{__('Both')}</Radio.Button>
                                    </Radio.Group>
                                  </GlobalS.CustomFormItem>
                                </S.Dropdowninner>
                              ))}
                            </S.Dropdown>
                          </div>
                        )
                      }
                    </Form.List>
                    <Form.List name="runningTotals">
                      {
                        (fields) => (
                          <div className="ant-form-item">
                            <Button
                              onClick={() => setActiveDropdown((e) => (e ? null : 'runningTotals'))}> {__('Totals')}</Button>
                            <S.Dropdown isVisible={activeDropdown === 'runningTotals' && fields?.length}>
                              {fields?.map((field, index) => (
                                <S.Dropdowninner key={field.key}>
                                  <GlobalS.CustomFormItem name={[index, 'measures']}>
                                    <CustomInput items={reportsWidgetConfiguration?.measures}/>
                                  </GlobalS.CustomFormItem>
                                  <GlobalS.CustomFormItem name={[index, 'percentage']}>
                                    <Radio.Group buttonStyle="solid">
                                      <Radio.Button value="absolute">{__('Absolute')}</Radio.Button>
                                      <Radio.Button value="relative">{__('Relative')}</Radio.Button>
                                      <Radio.Button value="keepBoth">{__('Both')}</Radio.Button>
                                    </Radio.Group>
                                  </GlobalS.CustomFormItem>
                                </S.Dropdowninner>
                              ))}
                            </S.Dropdown>
                          </div>
                        )
                      }
                    </Form.List>

                    <Form.List name={['visualization', 'pivotConfig']}>
                      {
                        (fields) => (
                          <div className="ant-form-item">
                            <Button onClick={() => setActiveDropdown((e) => (e ? null : 'pivot'))}><Icon
                              type="rotate-left"/> {__('Pivot')}</Button>
                            <S.Dropdown isVisible={activeDropdown === 'pivot' && fields?.length}>
                              {fields?.map((field, index) => (
                                <S.Dropdowninner key={field.key}>
                                  <GlobalS.CustomFormItem name={[index, 'value']}>
                                    <CustomInput items={[
                                      ...(reportsWidgetConfiguration?.measures || []),
                                      ...(reportsWidgetConfiguration?.dimensions || []),
                                      ...(reportsWidgetConfiguration?.timeDimensions || [])
                                    ]}
                                    />
                                  </GlobalS.CustomFormItem>
                                  <GlobalS.CustomFormItem name={[index, 'pivot']}>
                                    <Radio.Group buttonStyle="solid">
                                      <Radio.Button value="x">X</Radio.Button>
                                      <Radio.Button value="y">Y</Radio.Button>
                                    </Radio.Group>
                                  </GlobalS.CustomFormItem>
                                </S.Dropdowninner>
                              ))}
                            </S.Dropdown>
                          </div>
                        )
                      }
                    </Form.List>
                    <GlobalS.CustomFormItem name="limit" rules={[{required: true, message: 'Limit required'}]}>
                      <Input placeholder={__('Limit')} type="number"/>
                    </GlobalS.CustomFormItem>
                  </GlobalS.ItemListingContainer>
                </Form>
              </S.Container>
              <GlobalS.Title>{__('Preview')}</GlobalS.Title>
              <S.Container bottom={'small'}>
                <S.TabsWrapper>
                  <Tabs defaultActiveKey="1">
                    <TabPane tab={__("Chart")} key="1">
                      <S.ChartWrapper>
                        {(results && chartPayload) && (
                          <ErrorBoundary>
                            <Chart
                              query={chartPayload.cubeQuery.query}
                              results={results}
                              attributes={data.attributes}
                              mappings={data.mappings}
                              reportsWidgetConfiguration={data.reportsWidgetConfiguration}
                              {...formValues}
                            />
                          </ErrorBoundary>
                        )}

                        {loading === 'CHART' && <Loading partial/>}
                        {!loading && (!results || !chartPayload) &&
                          <div style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            <div>
                              <Space direction='vertical'>
                                <GlobalS.SubTitle>{__('Preview time unit')}</GlobalS.SubTitle>
                                <DatePicker
                                  onChange={(date) => setSpeedUpDate(date)}
                                  value={speedUpDate}
                                  format={dateFormat}/>
                                <Button disabled={!formValues?.dimensions?.length && !formValues?.measures?.length}
                                        onClick={() => handleGetChartResults({preview: speedUpDate.format(dateFormat)})}
                                        type="primary" size="large">
                                  {__("Load chart")}
                                </Button>
                              </Space>
                            </div>
                          </div>
                        }
                      </S.ChartWrapper>
                    </TabPane>
                    <TabPane tab={__("JSON Payload")} key="2">
                      <pre>{JSON.stringify(payload, null, 2)}</pre>
                    </TabPane>
                    <TabPane tab={__("JSON Form values")} key="3">
                      <pre>{JSON.stringify(formValues, null, 2)}</pre>
                    </TabPane>
                    <TabPane tab={__("JSON Payload chart")} key="4">
                      <pre>{JSON.stringify(chartPayload, null, 2)}</pre>
                    </TabPane>
                  </Tabs>
                </S.TabsWrapper>
              </S.Container>
            </Panel.Body>
            <Panel.Footer>
              <div className="group">
                <Popconfirm
                  placement="rightBottom"
                  title={__("Unsaved changes will be lost!")}
                  onConfirm={() => {
                    hashHistory.goBack()
                  }}
                  okText="Exit"
                  showCancel={false}
                >
                  <Button type="text">
                    {__('Exit')}
                  </Button>
                </Popconfirm>
                <button className="btn btn-primary pull-right" type="submit" onClick={handleSubmit}>
                  {__('Save')}
                </button>
              </div>
            </Panel.Footer>
          </GlobalS.CustomPanel>
        )}
      </div>
    </div>
  )
}
